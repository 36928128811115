import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Tag, Modal } from "antd";
import ProfileAvatal from "../../buttons/ProfileAvatal";
import { searchValue } from "../../../utils/setColor";
import DeleteModal from "../../Modals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserActions } from "../../../store/team/actions";
import NewTeamForm from "../../forms/NewTeamForm";

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>("");
  const { auth, user } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleOpenDelete = async (value: any) => {
    setUserToDelete(value);
    setVisible(true);
  };

  const handleEdit = async () => {
    setShowEdit(true);
  };

  const handleCancelEdit = () => {
    setShowEdit(false);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
    setUserToDelete("");
  };
  const deleteUser = async () => {
    auth?.token &&
      userToDelete?._id &&
      (await deleteUserActions(
        userToDelete?._id,
        {
          isDeleted: true,
        },
        auth?.token
      )(dispatch));
  };

  return (
    <>
      <div className="relative sm:w-[48%] xl:w-[30%] bg-white text-black rounded-lg p-1 border border-gray-100  hover:bg-white h-40 hover:border-gray-300">
        <div className="flex items-start justify-between p-3">
          <Stack direction={"row"} spacing={2} className="w-[65%]">
            <Stack spacing={2}>
              <div className="flex items-center justify-center w-16 text-center">
                <ProfileAvatal
                  picture={props?.data?.data?.picture}
                  name={props?.data?.data?.names}
                  userId={props?.data?.data?._id}
                />
              </div>
            </Stack>
            <Stack
              direction={"column"}
              spacing={2}
              className="cursor-pointer w-full h-36"
            >
              <Stack spacing={0}>
                <h1 className="capitalize">{props.data?.data?.names}</h1>
                <p className="text-xs capitalize" style={{ color: "blue" }}>
                  {props.data?.data?.role}
                </p>
              </Stack>
              <Box sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems="flex-end"
                  sx={{ width: "100%" }}
                >
                  <Stack spacing={1}>
                    <p className="text-xs">{props.data?.data?.email}</p>
                    <p className="text-xs">{props.data?.data.phone}</p>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Stack>

          <Tag
            color={searchValue(
              props.data?.data?.isActive === true ? "Active" : "Not Active"
            )}
            className="absolute top-3 right-2"
          >
            {props.data?.data?.isActive === true ? "Active" : "Not Active"}
          </Tag>
          <div className="absolute bottom-1 right-2">
            <IconButton
              aria-label="fingerprint"
              color="primary"
              size="small"
              onClick={handleEdit}
            >
              <BorderColorIcon />
            </IconButton>
            {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
              <IconButton
                aria-label="fingerprint"
                color="error"
                size="small"
                onClick={() => handleOpenDelete(props?.data?.data)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteUser}
        onCancel={handleDeleteModalCancels}
        itemName={userToDelete?.names}
        isLoading={user?.isFetching}
      />

      <Modal
        title={"Edit User Information"}
        open={showEdit}
        onCancel={handleCancelEdit}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className="w-[100vh]">
            {
              <NewTeamForm
                dataToUpdate={props?.data}
                action={"update"}
                handleCancel={handleCancelEdit}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Component;
