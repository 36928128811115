import * as React from "react";
import { Box, ThemeProvider, createTheme } from "@mui/system";
import millify from "millify";

const theme = createTheme({
  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    success: {
      dark: "#009688",
    },
  },
});

export default function SummaryCard(props: any) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            bgcolor: props?.bgColor ? props.bgColor : "background.paper",
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
            minWidth: 250,
          }}
        >
          <Box sx={{ color: "text.secondary" }}>{props?.title}</Box>
          <Box sx={{ color: "text.primary", fontSize: 24, fontWeight: "bold" }}>
            {millify(props?.value)}
          </Box>
        </Box>
      </ThemeProvider>{" "}
    </>
  );
}
