import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { ReactComponent as DashboardIcon } from "../icons/dashboard/dashboard_vector_icon.svg";
import { ReactComponent as BlogIcon } from "../icons/dashboard/blog.svg";
import { ReactComponent as ProductIcon } from "../icons/dashboard/products_vector_icon.svg";
import { ReactComponent as ServiceIcon } from "../icons/dashboard/vector_icon.svg";
import { ReactComponent as TestimonialIcon } from "../icons/dashboard/vector.svg";

export const listManages = [
  {
    title: "Dasboard",
    path: "/dashboard",
    disabled: false,
    icon: <DashboardIcon className="SamIcon" />,
    roles: ["admin", "dev", "manager"],
  },
  {
    title: "Service",
    path: "/services",
    icon: <ServiceIcon className="SamIcon" />,
    roles: ["admin", "manager", "dev"],
  },
  {
    title: "Product",
    path: "/products",
    icon: <ProductIcon className="SamIcon" />,
    roles: ["admin", "dev", "manager"],
  },
  {
    title: "Testimonial",
    path: "/testimonial",
    icon: <TestimonialIcon className="SamIcon text-gray-500" />,
    roles: ["admin", "dev", "manager"],
  },
  {
    title: "Blogs",
    path: "/blogs",
    icon: <BlogIcon className="SamIcon" />,
    roles: ["admin", "dev", "manager"],
  },
  {
    title: "Team",
    path: "/team",
    icon: <ManageAccountsOutlinedIcon className="SamIcon" />,
    roles: ["admin", "dev", "manager"],
  },
  {
    title: "ORDER",
    path: "/orderCard",
    icon: <BlogIcon className="SamIcon text-red-600" />,
    roles: ["admin", "dev", "manager"],
  },
];
