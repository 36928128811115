import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";

const DashRoutes = (props: any) => {
  const token = store.get("authToken");
  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Products",
      path: `/products`,
    },
    {
      caption: "Category",
      path: `/products/category`,
    },
  ];
  return authent.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={
        <TopNav
          nav={listManages.find(
            (item) => item.title.toLowerCase() === "Product".toLowerCase()
          )}
          pages={pagesArray}
        />
      }
      selectedNav={2}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default DashRoutes;
