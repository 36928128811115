import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import productReducer from "./product";
import userReducer from "./team";
import testimonialReducer from "./testimonial";
import blogsReducer from "./blogPost";
import apitourismReducer from "./apitourism";
import ordercardReducer from "./ordercard";

const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    user: userReducer,
    testimonial: testimonialReducer,
    blog: blogsReducer,
    apitourism: apitourismReducer,
    ordercard: ordercardReducer,
  },
});

export default store;
