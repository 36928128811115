import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createServiceUser = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/user/register`, data, token);
};
export const getAllServiceUser = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/users${query}`, token);
};

export const getOneServiceUser = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/one/${itemId}`, token);
};

export const updateServiceUser = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceUser = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/one/${itemId}`,
    data,
    token
  );
};
