import { notification } from "antd";
import { myApitourismActions } from ".";
import {
  createApitourism,
  getAllApitourism,
  getOneApitourism,
  updateApitourism,
  getAllApitourismCategoryService ,
  createApitourismCatService,
  updateApitourismCatService,

} from "./services";

export const createApitourismAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await createApitourism(data, token);
      const resAll = await getAllApitourism(token, "");
      if (res?.status === 201) {
        dispatch(myApitourismActions.setNew(res));
        dispatch(myApitourismActions.setAll(resAll));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const createApitourismCatAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await createApitourismCatService(data, token);
      const resAll = await getAllApitourismCategoryService(token, "?");
      console.log("res?.status", res);
      if (res?.status === 201) {
        dispatch(myApitourismActions.setNewApitourismCategory(res));
        dispatch(myApitourismActions.setApitourismCategory(resAll));
        dispatch(myApitourismActions.setIsFetching(false));
        notification.success({ message: "Category Added Successfully" });
      } else if (res?.response?.data?.status === 500) {
        console.log("res?.status", res?.status);
        notification.error({ message: "Category Name already exist" });
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllApitourismAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await getAllApitourism(token, query);
      if (res?.status === 200) {
        dispatch(myApitourismActions.setAll(res));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllApitourismCategoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await getAllApitourismCategoryService(token, query);
      if (res?.status === 200) {
        dispatch(myApitourismActions.setApitourismCategory(res));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneApitourismAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await getOneApitourism(id, token);
      if (res?.status === 200) {
        dispatch(myApitourismActions.setSelected(res?.data));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateApitourismAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await updateApitourism(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllApitourism(token, "");
        dispatch(myApitourismActions.setAll(resAll));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const  updateApiCatctsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myApitourismActions.setIsFetching(true));
      const res = await updateApitourismCatService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllApitourismCategoryService(token, "");
        dispatch(myApitourismActions.setApitourismCategory(resAll));
        dispatch(myApitourismActions.setIsFetching(false));
      }
      dispatch(myApitourismActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

