import React from "react";
import DashLayout from "../../components/layout/Layout";
import "chartkick/chart.js";
import DashboardSection from "../../components/dashboard/Sections/DashboardSection";

const PageView = (_props: any) => {
  return (
    <DashLayout>
      <div className="text-black piece_sold bg-white pb-12 mt-10">
        <div className="mx-auto">
          <DashboardSection />
        </div>
      </div>
    </DashLayout>
  );
};

export default PageView;
