import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product`, data, token);
};

export const createProductCatService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product/category`, data, token);
};

export const getAllService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/product${query}`, token);
};

export const getAllProdCateoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/product/category${query}`, token);
};

export const getOneService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/product/one/${id}`, token);
};

export const updateService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/product/one/${itemId}`,
    data,
    token
  );
};

export const updatecatService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/product/category/one/${itemId}`,
    data,
    token
  );
};

export const deleteService = async (itemId: string, token: string) => {
  return await HttpRequest.delete(`${SERVER_URL}/product/one/${itemId}`, token);
};
