import { notification } from "antd";
import { myTeamActions } from ".";
import {
  createServiceUser,
  getAllServiceUser,
  deleteServiceUser,
  getOneServiceUser,
  updateServiceUser,
} from "./services";

export const createUserAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTeamActions.setIsFetching(true));
      const res = await createServiceUser(data, token);
      const resAll = await getAllServiceUser(token, "");
      if (res?.status === 201) {
        dispatch(myTeamActions.setNew(res));
        dispatch(myTeamActions.setAll(resAll));
        dispatch(myTeamActions.setIsFetching(false));
        notification.success({ message: "Created Succesfully" });
      } else if (res?.code === "ERR_BAD_REQUEST") {
        notification.error({
          message: "Email Already Exist",
        });
      }
      dispatch(myTeamActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneUserAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTeamActions.setIsFetching(true));
      const res = await getOneServiceUser(id, token);
      if (res?.status === 200) {
        dispatch(myTeamActions.setSelected(res?.data));
        dispatch(myTeamActions.setIsFetching(false));
      }
      dispatch(myTeamActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllUsersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTeamActions.setIsFetching(true));
      const res = await getAllServiceUser(token, query);
      if (res?.status === 200) {
        dispatch(myTeamActions.setAll(res));
        dispatch(myTeamActions.setIsFetching(false));
      }
      dispatch(myTeamActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteUserActions = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTeamActions.setIsFetching(true));
      const res = await deleteServiceUser(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllServiceUser(token, "");
        dispatch(myTeamActions.setAll(resAll));
        dispatch(myTeamActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
        return true;
      }
      dispatch(myTeamActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUserActions = (itemId: string, data: any, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTeamActions.setIsFetching(true));
      const res = await updateServiceUser(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllServiceUser(token, "");
        dispatch(myTeamActions.setSelected(res?.data));
        dispatch(myTeamActions.setAll(resAll));
        dispatch(myTeamActions.setIsFetching(false));
        dispatch(myTeamActions.setIsFetching(false));
        notification.success({ message: "Updated Succesfully" });
      }
      dispatch(myTeamActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
