import React from "react";
import { PaginationControlled } from "../filters/Paginate";
const ScrollableFrame: React.FC<{
  children: any;
  loading?: boolean;
  limit?: number;
  count?: number;
  total?: number;
  setLimit?: (limit: number) => void;
  setPage?: (page: number) => void;
  hidePagination?: boolean;
}> = ({
  children,
  loading,
  limit,
  count,
  total,
  setLimit,
  setPage,
  hidePagination,
}) => {
  return (
    <div className="space-y-4">
      <div className=" p-4 h-[calc(100vh-250px)] overflow-y-auto">
        {children}
      </div>
      <div className="">
        {!hidePagination && (
          <PaginationControlled
            loading={loading}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={total}
            count={count}
          />
        )}
      </div>
    </div>
  );
};

export default ScrollableFrame;
