import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "productLibrary",
  initialState: {
    isFetching: false,
    allBlog: null,
    allCategory: null,
    selectedBlog: null,
    new: null,
    updated: null,
    query: null,
    newBlogCategory: null,
  },
  reducers: {
    setAll(state, action) {
      state.allBlog = action.payload;
    },
    setAllCat(state, action) {
      state.allCategory = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelectedBlog(state, action) {
      state.selectedBlog = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNewBlogCategory(state, action) {
      state.newBlogCategory = action.payload;
    },
  },
});

export const myBlogActions = mySlice.actions;

export default mySlice.reducer;
