import { Button, Stack } from "@mui/material";
import { Form, Input, notification } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";

import {
  createProductCatAction,
  updateProduCatctsAction,
} from "../../store/product/actions";
import { useEffect } from "react";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewProductCategoryForm: React.FC<{
  dataToUpdate: any;
  action: String;
  handleCancel: any;
}> = ({ dataToUpdate, action, handleCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { product, auth } = useSelector((state: any) => state);
  const isUpdate = action === "update";

  const onFinish = async (values: any) => {
    const payload = {
      ...values,
    };

    if (dataToUpdate) {
      dataToUpdate?._id &&
        (await updateProduCatctsAction(auth.token, dataToUpdate?._id, {
          ...values,
        })(dispatch));
      notification.success({ message: "Updated Successfully" });
    } else {
      await createProductCatAction(auth?.token, { ...payload })(dispatch);
    }

    form.resetFields();
    handleCancel();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = {
    name: dataToUpdate?.name,
  };

  useEffect(() => {
    if (dataToUpdate) {
      form.setFieldsValue(initialData);
    } else {
      form.resetFields();
    }
  }, [form, initialData, dataToUpdate]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialData || {}}
      style={{ maxWidth: "80%" }}
      scrollToFirstError
    >
      <div className="grid grid-cols-1 mr-6 pl-36">
        <Form.Item
          name="name"
          label="Category Name"
          rules={[
            {
              required: true,
              message: "Category Name is required!",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="flex-start"
        justifyContent={"start"}
        sx={{ width: "100%" }}
      >
        <Stack sx={{ width: "75%" }}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              loading={product?.isFetching}
              style={{
                backgroundColor: "#fcc31ad5",
                color: "white",
                fontSize: "14px",
              }}
            >
              {isUpdate ? "Update" : "Submit"}
            </LoadingButton>
          </Form.Item>
        </Stack>
      </Stack>
    </Form>
  );
};

export default NewProductCategoryForm;
