import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// import NoImageOnPost from "../assets/images/NoImageOnPost.jpg";
// import { getSinglePost } from "../redux/actions";
import moment from "moment";
import parse from "html-react-parser";
import { useMediaQuery } from "@mui/material";
import { getOneBlogAction } from "../../../store/blogPost/actions";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const ViewBlogPost = () => {
  const dispatch = useDispatch();
  const { auth, blog } = useSelector((state: any) => state);
  const { blogId } = useParams();
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (auth?.token && blogId) getOneBlogAction(auth?.token, blogId)(dispatch);
  }, [auth, dispatch, blogId]);

  return (
    <div className="text-gray-900">
      <div className="pt-4">
        <DetailsHeaderActionBar pageName="Blogs" title="View" />
      </div>
      <div className={`flex flex-col bg-white px-10`}>
        {blog?.selectedBlog && (
          <div className="flex flex-col">
            <div>
              <h1 className="block text-2xl font-semibold text-gray-800 py-6 md:text-3xl">
                {blog?.selectedBlog?.title}
              </h1>
              <div className="flex items-center mb-4">
                <img
                  className="object-cover object-center w-10 h-10 rounded-full"
                  src={
                    blog?.selectedBlog?.createdBy?.picture ??
                    "https://www.a1honey.co.za/wp-content/uploads/2017/04/What_is_Honey_and_how_is_it_made_A1_Honey_South_Africa.png"
                  }
                  alt=""
                />

                <div className="mx-4">
                  <h1 className="text-sm text-gray-700 ">
                    {blog?.selectedBlog?.createdBy?.names}
                  </h1>
                  <p className="text-sm text-gray-500 capitalize">
                    {blog?.selectedBlog?.createdBy?.role}
                  </p>
                </div>
              </div>
              <div className="border-t-gray-300 border-t-[1px] border-b-gray-300 border-b-[1px] mb-6">
                <p className="text-gray-700 text-base py-2">
                  {moment(blog?.selectedBlog?.createdAt).format(
                    "dddd, Do MMMM YYYY"
                  )}
                </p>
              </div>
            </div>
            {blog?.selectedBlog?.picture && (
              <img
                className="object-cover lg:mx-6 rounded-xl h-[60vh]"
                src={blog?.selectedBlog?.picture}
                alt=""
              />
            )}
            <p className="block mt-4 text-xl text-gray-800 pt-6">
              {blog?.selectedBlog?.description}
            </p>
            <div className="block mt-4 text-xl text-gray-800 py-5">
              {parse(blog?.selectedBlog?.content.toString())}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBlogPost;
