import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, Select, Col, notification } from "antd";
import UploadComponent from "./Upload";
import { useSelector, useDispatch } from "react-redux";
import {
  createApitourismAction,
  getAllApitourismCategoryAction,
  updateApitourismAction,
} from "../../store/apitourism/actions";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewApitourismForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const { auth, apitourism } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if (props?.dataToUpdate) {
      await updateApitourismAction(auth.token, props?.dataToUpdate?._id, {
        ...values,
        category: values?.category
          ? values?.category
          : dataToUpdate?.category?._id,
        picture: images[0],
      })(dispatch);
      notification.success({ message: "Updated Successfully" });
    } else {
      auth?.token &&
        (await createApitourismAction(auth?.token, {
          ...values,
          picture: images[0],
        })(dispatch));
    }
    props?.onCancel();
    form.resetFields();
  };

  const dataToUpdate = {
    ...props?.dataToUpdate,
  };

  useEffect(() => {
    auth?.token && getAllApitourismCategoryAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = {
    ...dataToUpdate,
    category: dataToUpdate?.category?.name,
  };

  useEffect(() => {
    if (props?.dataToUpdate) {
      form.setFieldsValue(initialData);
    } else {
      form.resetFields();
    }
  }, [form, initialData, props?.dataToUpdate]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      layout="vertical"
      name="register"
      onFinish={onFinish}
      initialValues={initialData || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <div className="flex justify-center items-center ml-4 mb-2">
        <UploadComponent
          setPictures={setPictures}
          isCard={"picture-card"}
          limit={1}
          default={
            props?.dataToUpdate && [
              {
                status: "done",
                url: props?.dataToUpdate?.picture,
              },
            ]
          }
        />
      </div>
      <Form.Item
        name="names"
        label="Title"
        tooltip="Please enter title"
        rules={[
          {
            required: true,
            message: "Title is required!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="category"
        label="Service Category"
        // initialValue={dataToUpdate?.category?.name}
        rules={[
          {
            required: true,
            message: "Category is required!",
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          loading={apitourism.isFetching}
          disabled={apitourism.isFetching}
          // style={{ width: 200 }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toString().includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toString()
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toString().toLowerCase())
          }
          options={apitourism?.category?.data?.map((category: any) => ({
            label: `${category?.name}`,
            value: category?._id,
          }))}
        />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required!" }]}
      >
        <TextArea
          placeholder="Controlled autosize"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
      <LoadingButton
        type="submit"
        variant="contained"
        sx={{ minWidth: "50%" }}
        style={{
          backgroundColor: "#fcc31ad5",
          color: "white",
          fontSize: "14px",
        }}
        loading={apitourism?.isFetching}
      >
        {props?.dataToUpdate ? "update" : "Save"}
      </LoadingButton>
    </Form>
  );
};

export default NewApitourismForm;
