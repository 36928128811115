import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Table } from "antd";
import { LineChart } from "react-chartkick";
import OrderTable from "../../cards/dashboard/OrderTable";
const DashboardSection = (_props: any) => {
  const datas = [
    {
      name: "Current Year",
      data: {
        "2021-01-01": 0,
        "2021-01-02": 0,
        "2021-01-03": 0,
        "2021-01-04": 0,
        "2021-01-05": 0,
        "2021-01-06": 0,
        "2021-01-07": 0,
        "2021-01-08": 0,
        "2021-01-09": 0,
        "2021-01-10": 0,
        "2021-01-11": 0,
        "2021-01-12": 0,
      },
      color: "green",
    },
    {
      name: "Previous Year",
      data: {
        "2021-01-01": 0,
        "2021-01-02": 0,
        "2021-01-03": 0,
        "2021-01-04": 0,
        "2021-01-05": 0,
        "2021-01-06": 0,
        "2021-01-07": 0,
        "2021-01-08": 0,
        "2021-01-09": 0,
        "2021-01-10": 0,
        "2021-01-11": 0,
        "2021-01-12": 0,
      },
      color: "gray",
    },
  ];
  

  return (
    <div className="flex flex-wrap lg:flex-nowrap flex-col w-full h-fit xl:flex-row mx-auto mt-10 gap-y-2 lg:gap-x-4">
      <div className="flex-1 flex flex-col w-full gap-2 relative">
        <div className=" flex-auto bg-white p-2 max-h-[432px] rounded-md">
          <p className="text-gray-400 capitalize xmb-2">ORDER'S INFOR</p>
          <div className={`absolute top-2 right-2 rounded-lg px-3 py-1`}>

            <span className={` flex items-center py-0.5`}>
              <ArrowUpwardIcon /> 
            </span>
          </div>
        </div>
        <OrderTable orders={[]}/>
       
      </div>
      
    </div>
  );
};

export default DashboardSection;
