import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../components/HeaderComponents";
import { Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DeleteModal from "../../../components/Modals/DeleteModal";
import NewTestimonialForm from "../../../components/forms/NewTestimonialForm";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  getAllApitourismAction,
  updateApitourismAction,
} from "../../../store/apitourism/actions";
import NewApitourismForm from "../../../components/forms/NewApitourismForm";

const Service = (props: any) => {
  const { auth, testimonial, apitourism } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>("");
  const [dataToUpdate, setDataToUpdate] = useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    auth?.token && getAllApitourismAction(auth?.token, `?`)(dispatch);
    console.log(apitourism);
  }, [auth?.token, dispatch]);

  const handleChagePublicationStatus = async (id: string, data: boolean) => {
    if (auth?.token && id) {
      await updateApitourismAction(auth.token, id, {
        isPublished: data,
      })(dispatch);
    }
  };

  const handleEdit = async (value: any) => {
    setDataToUpdate(value);
    setShowEdit(true);
  };

  const handleCancelEdit = () => {
    setShowEdit(false);
    setDataToUpdate("");
  };

  const handleOpenDelete = async (value: any) => {
    setUserToDelete(value);
    setVisible(true);
  };

  const handlecanceDelete = async (value: any) => {
    setUserToDelete("");
    setVisible(false);
  };

  const deleteTestimonial = async () => {
    auth?.token &&
      userToDelete &&
      (await updateApitourismAction(auth?.token, userToDelete, {
        isDeleted: true,
      })(dispatch));
    setVisible(false);
  };

  console.log("dddd", apitourism?.all?.data);

  return (
    <>
      <div className="text-black flex items-center justify-between mt-7 ml-5 lg:mr-8 2xl:mr-24">
        <DetailsHeaderActionBar pageName="Apitourism" title=" " />
        <HeaderComponent
          item="Apitourism"
          modelTitle="Add Apitourism"
          isNotCollapse={true}
          ModelComponent={<NewApitourismForm onCancel={handleCancel} />}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>
      <div className="flex flex-wrap gap-3 h-full w-full bg-white pb-2 text-gray-900">
        <div className="flex flex-wrap gap-3 h-full w-full bg-white pb-2 text-gray-900">
          {apitourism?.all?.data?.map((el: any) => (
            <div className="w-[300px] h-[500px] rounded overflow-hidden shadow-lg relative">
              <img
                className="w-full h-[250px] object-cover"
                src={el?.picture}
                alt="Sunset in the mountains"
              />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{el?.names}</div>
                <p className="text-gray-700 text-base">{el?.description}</p>
              </div>
              <div className="px-6 pt-4 pb-2 absolute right-0 bottom-2">
                <IconButton
                  aria-label="fingerprint"
                  color="primary"
                  size="small"
                  onClick={() => handleEdit(el)}
                >
                  <BorderColorIcon />
                </IconButton>
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  size="small"
                  onClick={() => handleOpenDelete(el?._id)}
                >
                  <DeleteIcon />
                </IconButton>
                <Switch
                  checked={el?.isPublished}
                  onChange={(e) => handleChagePublicationStatus(el?._id, e)}
                  className="bg-gray-800 text-xl"
                  defaultChecked
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeleteModal
        visible={visible}
        onOk={deleteTestimonial}
        onCancel={handlecanceDelete}
        itemName={"Apitourism"}
        isLoading={testimonial?.isFetching}
      />
      <Modal
        title={"Update Apitourism"}
        open={showEdit}
        onCancel={handleCancelEdit}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className="w-[100vh]">
            {
              <NewApitourismForm
                dataToUpdate={dataToUpdate}
                action={"update"}
                onCancel={handleCancelEdit}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Service;
