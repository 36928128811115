import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logo from "../../assets/images/logo.png";
import { listManages } from "../../assets/data/pages";
import { useSelector } from "react-redux";

const IconMenu: React.FC<{
  selected: number;
}> = ({ selected }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selected);
  const { auth } = useSelector((state: any) => state);
  const navigate = useNavigate();

  return (
    <div
      className={`fixed flex w-fit flex-col min-h-screen  items-center !bg-white z-50 `}
    >
      <div className="flex justify-center items-center h-16 w-full">
        <img src={Logo} alt="" className="w-20" />
      </div>
      <div
        className={`flex flex-col flex-none justify-center items-center xw-[100%] max-w-full space-y-5 pt-8 pb-8 xfixed left-0 top-0 md:static xlg:translate-x-0 xz-50 duration-300`}
      >
        <>
          {listManages.map(
            (manage: any, index: number) =>
              (!manage?.disabled ||
                manage?.roles?.includes(auth?.user?.role?.toLowerCase())) && (
                <>
                  {/* <Divider /> */}
                  <MenuItem
                    className="hover:!text-blue-500 hover:!bg-white space-x-5 !p-0 !mx-0 !my-1"
                    disabled={
                      manage?.disabled ||
                      !manage?.roles?.includes(auth?.user?.role?.toLowerCase())
                    }
                    onClick={() => {
                      setSelectedTabIndex(index);
                      navigate(manage.path);
                    }}
                    disableRipple
                  >
                    <ListItemIcon
                      className={`SideNav ${
                        selectedTabIndex === index
                          ? "text-blue-600"
                          : "text-gray-500"
                      } !m-0 !p-0`}
                    >
                      <div
                        className={`flex flex-col items-center ${
                          selectedTabIndex === index ? "bg-[#f5f7fa] p-1" : null
                        } w-20 overflow-hidden`}
                      >
                        {manage.icon}
                        {manage?.title}
                      </div>
                    </ListItemIcon>
                  </MenuItem>
                </>
              )
          )}
        </>
      </div>
    </div>
  );
};

export default IconMenu;
