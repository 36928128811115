import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, Row, Col, notification } from "antd";
import UploadComponent from "./Upload";
import { useSelector, useDispatch } from "react-redux";
import { createUserAction, updateUserActions } from "../../store/team/actions";
import {
  addTestimonialAction,
  updateTestimonialsAction,
} from "../../store/testimonial/actions";
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewTestimonialForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const { testimonial, auth } = useSelector((state: any) => state);

  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if (props?.dataToUpdate) {
      await updateTestimonialsAction(auth.token, props?.dataToUpdate?._id, {
        ...values,
        picture: images[0],
      })(dispatch);
      notification.success({ message: "Updated Successfully" });
    } else {
      auth?.token &&
        (await addTestimonialAction(auth?.token, {
          ...values,
          picture: images[0],
        })(dispatch));
    }
    props?.onCancel();
    form.resetFields();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = {
    ...props?.dataToUpdate,
  };

  useEffect(() => {
    if (props?.dataToUpdate) {
      form.setFieldsValue(initialData);
    } else {
      form.resetFields();
    }
  }, [form, initialData, props?.dataToUpdate]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      layout="vertical"
      name="register"
      onFinish={onFinish}
      initialValues={initialData || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <div className="flex justify-center items-center ml-4 mb-2">
        <UploadComponent
          setPictures={setPictures}
          limit={1}
          default={
            props?.dataToUpdate && [
              {
                status: "done",
                url: props?.dataToUpdate?.picture,
              },
            ]
          }
        />
      </div>
      <Form.Item
        name="names"
        label="Name"
        tooltip="Please enter  name of the Employee?"
        rules={[
          {
            required: true,
            message: "Name is required!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required!" }]}
      >
        <TextArea
          placeholder="Controlled autosize"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
      <LoadingButton
        type="submit"
        variant="contained"
        sx={{ minWidth: "50%" }}
        style={{
          backgroundColor: "#fcc31ad5",
          color: "white",
          fontSize: "14px",
        }}
        loading={testimonial?.isFetching}
      >
        {props?.dataToUpdate ? "update" : "Save"}
      </LoadingButton>
    </Form>
  );
};

export default NewTestimonialForm;
