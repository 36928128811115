import { BrowserRouter } from "react-router-dom";
import MainRouter from "./routes/MainRouter";
import { ConfigProvider } from "antd";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import SplashScreen from "./components/layout/SplashScreen_02";
import { useSelector } from "react-redux";
import AutoLogout from "./components/layout/AutoLogout";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4C39D4",
    },
  },
  typography: {
    fontFamily: "'SF Pro Display', sans-serif",
    fontSize: 12,
    fontWeightLight: 800,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {},
});

const App = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [staticUser, setStaticUser] = useState<any>(null);
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    setTimeout(() => setShowSplash(false), 2000);
  }, []);

  useEffect(() => {
    if (user && user != staticUser) {
      setStaticUser(user);
    }
  }, [user]);

  return (
    <BrowserRouter>
      <AutoLogout />
      {showSplash ? (
        <SplashScreen />
      ) : (
        <ConfigProvider
          theme={{
            token: {
              fontSize: 12,
              colorPrimary: "#4C39D4",
              fontFamily: "'SF Pro Display', sans-serif",
            },
            components: { Button: { colorBgElevated: "yellow" } },
          }}
        >
          <ThemeProvider theme={theme}>
            <MainRouter />
          </ThemeProvider>
        </ConfigProvider>
      )}
    </BrowserRouter>
  );
};

export default App;
