import axios from "axios";
import { SERVER_URL } from "../../utils/constants";

export const loginService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/login`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const profileService = async (token: String) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${SERVER_URL}/user/profile`,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const userProfileBasicService = async (email: String) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${SERVER_URL}/user/userInfo/${email}`,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};
