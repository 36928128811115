import React from "react";
import { Result } from "antd";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const App = (props: any) => {
  return (
    <Result
      status="success"
      title={props?.title}
      subTitle={props?.proforma?.new?.data?.quotationId}
      extra={
        <div>
          <PrimaryButton
            name="Back"
            variant="contained"
            onClick={props?.closeTab}
          />
          <PrimaryButton
            name=" Visit Our Website"
            style={{ color: "green", borderColor: "green" }}
          />
        </div>
      }
      style={{ background: "white", minHeight: "20vh" }}
    />
  );
};

export default App;
