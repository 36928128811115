import { notification } from "antd";
import { myTestimonialActions } from ".";
import {
  createService,
  getAllService,
  getOneService,
  updateService,
} from "./services";

export const addTestimonialAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTestimonialActions.setIsFetching(true));
      const res = await createService(data, token);
      const resAll = await getAllService(token, "");
      if (res?.status === 201) {
        dispatch(myTestimonialActions.setNew(res));
        dispatch(myTestimonialActions.setAll(resAll));
        dispatch(myTestimonialActions.setIsFetching(false));
      }
      dispatch(myTestimonialActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTestimonialsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTestimonialActions.setIsFetching(true));
      const res = await getAllService(token, query);
      if (res?.status === 200) {
        dispatch(myTestimonialActions.setAll(res));
        dispatch(myTestimonialActions.setIsFetching(false));
      }
      dispatch(myTestimonialActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneTestimonialAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTestimonialActions.setIsFetching(true));
      const res = await getOneService(id, token);
      if (res?.status === 200) {
        dispatch(myTestimonialActions.setSelected(res?.data));
        dispatch(myTestimonialActions.setIsFetching(false));
      }
      dispatch(myTestimonialActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateTestimonialsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTestimonialActions.setIsFetching(true));
      const res = await updateService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllService(token, "");
        dispatch(myTestimonialActions.setAll(resAll));
        dispatch(myTestimonialActions.setIsFetching(false));
      }
      dispatch(myTestimonialActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
