import React, { useEffect, useState } from "react";
import { Modal, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../Modals/DeleteModal";
import {
  getAllApitourismCategoryAction,
  updateApiCatctsAction,
} from "../../store/apitourism/actions";
import NewApitourismCategoryForm from "../forms/NewApitourismCategoryForm";

interface categoryType {
  key: string;
  _id: string;
  name: string;
}

const ApiCatTable = (props: any) => {
  const { auth, product, apitourism } = useSelector((state: any) => state);
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [isUpdateModel, setIsUpdateModel] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [dataToUpdate, setDataToUpdate] = useState<any>("");
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getAllApitourismCategoryAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);

  const openDeleteModel = (value: string) => {
    setCategoryId(value);
    setIsDeleteModel(true);
  };
  const onCancelDeleteModel = () => {
    setIsDeleteModel(false);
    setCategoryId("");
  };

  const openUpdateModel = (value: any) => {
    setDataToUpdate(value);
    setIsUpdateModel(true);
  };
  const onCancelUpdateModel = () => {
    setIsUpdateModel(false);
    setDataToUpdate("");
  };

  const handleDeleteCategory = async () => {
    categoryId &&
      (await updateApiCatctsAction(auth?.token, categoryId, {
        isDeleted: true,
      })(dispatch));
    notification.success({ message: "Removed Successfully" });
    setIsDeleteModel(false);
  };

  return (
    <>
      <div className="bg-white">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-[#f6f8fa] text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-left">Created By</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {apitourism?.category?.data?.map((cat: any) => (
              <tr className="border-b border-gray-200 hover:bg-gray-100">
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <span className="font-medium">{cat?.name}</span>
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  <span className="font-medium">{cat?.createdBy?.names}</span>
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex item-center justify-center">
                    <button
                      className="w-4 mr-2 transform hover:text-[#1f2937] hover:scale-110"
                      onClick={() => openUpdateModel(cat)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => openDeleteModel(cat?._id)}
                      className="w-4 mr-2 transform hover:scale-110 text-red-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DeleteModal
        visible={isDeleteModel}
        onOk={handleDeleteCategory}
        onCancel={onCancelDeleteModel}
        itemName={"Service Category"}
        isLoading={product?.isFetching}
      />
      <Modal
        title={"Update Category"}
        open={isUpdateModel}
        onCancel={onCancelUpdateModel}
        footer={null}
        className="min-w-max"
      >
        <NewApitourismCategoryForm
          dataToUpdate={dataToUpdate}
          action={"update"}
          handleCancel={onCancelUpdateModel}
        />
      </Modal>
    </>
  );
};

export default ApiCatTable;
