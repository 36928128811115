import HeaderComponent from "../../../components/HeaderComponents";
import { Stack, Box, Skeleton } from "@mui/material";
import TeamGrid from "../../../components/grids/team/TeamGrid";
import React, { useEffect, useState } from "react";
import AddNewTeamMember from "../../../components/forms/NewTeamForm";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getAllUsersAction } from "../../../store/team/actions";

const PageView = (props: any) => {
  const [isGridView, setIsGridView] = useState(true);
  const { auth, user } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAllUsersAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  return (
    <Stack>
      <div className="text-black flex items-center justify-between mt-7 ml-5 lg:mr-8 2xl:mr-24">
        <DetailsHeaderActionBar
          pageName="Team Member"
          goBack={goBack}
          title=" "
        />
        <HeaderComponent
          item="User"
          modelTitle="Add New User"
          isNotCollapse={true}
          ModelComponent={<AddNewTeamMember onCancel={handleCancel} />}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          setIsGridView={setIsGridView}
        />
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={user?.all?.total}
        count={Math.ceil(user?.all?.total / limit)}
      >
        {isGridView && (
          <Box sx={{ pt: 2 }}>
            <div className="flex flex-wrap gap-5 justify-start items-start">
              {user.isFetching && (
                <>
                  {[1, 2, 3].map(() => (
                    <Box sx={{ width: 250, p: 1 }}>
                      <Stack>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />

                          <Box sx={{ width: "100%" }}>
                            <Stack>
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                              />
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="100%"
                                style={{ marginBottom: 6 }}
                              />
                            </Stack>
                          </Box>
                        </Stack>
                        <Skeleton animation="wave" height={120} width="100%" />
                      </Stack>
                    </Box>
                  ))}
                </>
              )}
              {!user.isFetching && <TeamGrid data={user?.all?.data} />}
            </div>
          </Box>
        )}
      </ScrollableFrame>
    </Stack>
  );
};

export default PageView;
