import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Switch } from "antd";
import { updateBlogPostAction } from "../../../store/blogPost/actions";
import DeleteModal from "../../Modals/DeleteModal";

const BlogPost = () => {
  const { auth, blog } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const [blogPostId, setBlogPostId] = useState("");
  const dispatch = useDispatch();

  const handleChagePublicationStatus = async (id: string, data: boolean) => {
    if (auth?.token && id) {
      await updateBlogPostAction(auth.token, id, {
        isPublished: data,
      })(dispatch);
    }
  };

  const handleClickDelete = async (id: string) => {
    setVisible(true);
    setBlogPostId(id);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
    setBlogPostId("");
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await updateBlogPostAction(auth?.token, blogPostId, {
        isDeleted: true,
      })(dispatch));
    setVisible(false);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center w-[90%]">
        <div className="flex flex-row justify-between items-center w-[100%]">
          <h1 className="block text-[17px] md:text-2xl font-semibold text-gray-800 py-6 md:text-3x">
            Manage Blog Post
          </h1>
        </div>
        <div className="w-[100%] overflow-x-auto">
          <div className="bg-white  rounded my-6 w-[100%]">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-[#f6f8fa] text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Title</th>
                  <th className="py-3 px-6 text-left">Publisher</th>
                  <th className="py-3 px-6 text-center">Category</th>
                  <th className="py-3 px-6 text-center">Date</th>
                  <th className="py-3 px-6 text-center">Published</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {blog?.allBlog?.data?.map((post: any) => (
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="mr-2">
                          <img
                            src={post?.picture}
                            className="object-cover object-center w-10 h-10 rounded-full"
                            alt=""
                          />
                        </div>
                        <span className="font-medium">
                          {`${post?.title?.substring(0, 25)}...`}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-left">
                      <div className="flex items-center">
                        <div className="mr-2">
                          <img
                            className="w-6 h-6 rounded-full"
                            src={`${post?.createdBy?.picture}`}
                            alt=""
                          />
                        </div>
                        <span>{post?.createdBy?.names}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex items-center justify-center">
                        <span>{post?.category?.categoryName}</span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <span>
                        {moment(post?.createdAt).format("DD/MM/YYYY")}
                      </span>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <Switch
                        checked={post?.isPublished}
                        onChange={(e) =>
                          handleChagePublicationStatus(post?._id, e)
                        }
                        className="bg-gray-800 text-xl"
                        defaultChecked
                      />
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <NavLink
                          to={`/blogs/view/${post?._id}`}
                          className="w-4 mr-2 transform hover:text-[#1f2937] hover:scale-110"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        </NavLink>
                        <NavLink
                          to={`/blogs/${post?._id}`}
                          className="w-4 mr-2 transform hover:text-[#1f2937] hover:scale-110"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </NavLink>
                        <button
                          onClick={() => handleClickDelete(post._id)}
                          className="w-4 mr-2 transform hover:scale-110 text-red-500"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={blog?.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Blog Post"
      />
    </>
  );
};

export default BlogPost;
