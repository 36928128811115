const statusColor = [
  ["Pending", "#c0c0c0"],
  ["Active", "#4CAF50"],
  ["Sold", "#F50721"],
  ["Paid", "#4CAF50"],
];

export const searchValue = (value: string) => {
  for (let i = 0; i < statusColor.length; i++) {
    if (statusColor[i][0]?.toLowerCase() === value?.toLowerCase()) {
      return statusColor[i][1];
      //   break;
    }
  }
};

export const getColorByValue = (arr: any, value: any) => {
  for (let i = 0; i < arr?.length; i++) {
    if (arr[i].value === value) {
      return arr[i].color;
    }
  }
  return null; // If the value is not found in the array, return null or any other appropriate value
};
