import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, Select, Row, Col } from "antd";
import UploadComponent from "./Upload";
import { roles } from "../../utils/roles";
import { useSelector, useDispatch } from "react-redux";
import { createUserAction, updateUserActions } from "../../store/team/actions";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewTeamForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const { user, auth } = useSelector((state: any) => state);

  console.log("dataToUpdate", props?.dataToUpdate);

  const onFinish = async (values: any) => {
    console.log("Received values of form: ", values);
    // console.log(pictures, "--------------------------------");
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if (props?.dataToUpdate) {
      auth?.token &&
        (await updateUserActions(
          props?.dataToUpdate?.data?._id,
          {
            ...values,
            picture: images[0],
          },
          auth?.token
        )(dispatch));
    } else {
      auth?.token &&
        (await createUserAction(auth?.token, {
          ...values,
          picture: images[0],
        })(dispatch));
    }
    form.resetFields();
    props?.handleCancel();
  };

  const handleSelectedRole = (value: string) => {
    setSelectedRole(value);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialData = {
    ...props?.dataToUpdate?.data,
  };

  useEffect(() => {
    if (props?.dataToUpdate) {
      form.setFieldsValue(initialData);
    } else {
      form.resetFields();
    }
  }, [form, initialData, props?.dataToUpdate]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={initialData || {}}
      style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <div className="flex justify-center items-center ml-48 mb-2">
        <UploadComponent
          setPictures={setPictures}
          limit={1}
          default={
            props?.dataToUpdate?.data && [
              {
                status: "done",
                url: props?.dataToUpdate?.data?.picture,
              },
            ]
          }
        />
      </div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="names"
            label="Name"
            tooltip="Please enter  name of the Employee?"
            rules={[
              {
                required: true,
                message: "Name is required!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="email"
            label="email"
            tooltip="Please enter email of the Employee?"
            rules={[{ required: true, message: "email is required!" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="phone"
            label="Phone"
            tooltip="Please enter  phone of the Employee?"
            rules={[
              {
                required: true,
                message: "Phone is required!",
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="+1 (377) 317-1945" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="password" label="password" tooltip="password">
            <Input.Password
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="nid"
            label="NID"
            tooltip="NID"
            rules={[{ required: true, message: "NID is required!" }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="address" label="Address" tooltip="Address">
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="role"
            label="Role"
            rules={[
              {
                required: true,
                message: "Please select role of the Employee!",
              },
            ]}
          >
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              placeholder="Select  Role of the Employee!"
              className="capitalize"
              onChange={handleSelectedRole}
            >
              {roles.map((type: any) => (
                <Option value={type.toLowerCase()} className="capitalize">
                  {type.split("-").join(" ")}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "75%" }}
              style={{
                backgroundColor: "#fcc31ad5",
                color: "white",
                fontSize: "14px",
              }}
              loading={user?.isFetching}
            >
              {props?.dataToUpdate ? "update" : "Save"}
            </LoadingButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewTeamForm;
