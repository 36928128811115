import { notification } from "antd";
import { myProductActions } from ".";
import {
  createService,
  getAllService,
  getOneService,
  updateService,
  getAllProdCateoryService,
  createProductCatService,
  updatecatService,
} from "./services";

export const createProductAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await createService(data, token);
      const resAll = await getAllService(token, "");
      if (res?.status === 201) {
        dispatch(myProductActions.setNew(res));
        dispatch(myProductActions.setAll(resAll));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createProductCatAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await createProductCatService(data, token);
      const resAll = await getAllProdCateoryService(token, "?");
      console.log("res?.status", res);
      if (res?.status === 201) {
        dispatch(myProductActions.setNewProductCategory(res));
        dispatch(myProductActions.setProductCategory(resAll));
        dispatch(myProductActions.setIsFetching(false));
        notification.success({ message: "Category Added Successfully" });
      } else if (res?.response?.data?.status === 500) {
        console.log("res?.status", res?.status);
        notification.error({ message: "Category Name already exist" });
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProductsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getAllService(token, query);
      if (res?.status === 200) {
        dispatch(myProductActions.setAll(res));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProductsCategoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getAllProdCateoryService(token, query);
      if (res?.status === 200) {
        dispatch(myProductActions.setProductCategory(res));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneProductAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getOneService(id, token);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateProductsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await updateService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllService(token, "");
        dispatch(myProductActions.setAll(resAll));
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateProduCatctsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await updatecatService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllProdCateoryService(token, "");
        dispatch(myProductActions.setProductCategory(resAll));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
