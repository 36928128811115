import { useEffect, useState } from "react";
import { Stack, Paper, Button, Divider } from "@mui/material";
import { Modal, Collapse } from "antd";
import { useNavigate } from "react-router-dom";
import SummaryCard from "./dashboard/SummaryCard";
const style = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: "100vh",
  color: "black",
  p: 2,
};

const { Panel } = Collapse;
const Component = (props: any) => {
  const [isGridView, setIsGridView] = useState(true);
  const navigate = useNavigate();
  const showModal = () => {
    !props.goForward && props?.setIsModalOpen(true);
    props.goForward && navigate(props.goForward);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  useEffect(() => {
    // props?.isGridView && props?.isGridView = isGridView
  }, []);

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "5px" }}>
        <Stack
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={0}
            // divider={<Divider orientation="vertical" flexItem />}
          >
            <h1>{props?.title}</h1>
            {props?.filterCard}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {!props?.isNotAddButton && (
              <Button
                variant="contained"
                onClick={showModal}
                sx={{ minWidth: 150 }}
                style={{
                  backgroundColor: "#fcc31ad5",
                  color: "white",
                  fontSize: "14px",
                }}
              >
                Add New {props?.item}
              </Button>
            )}
            {props?.isNotAddButton && props.OtherButton}
          </Stack>
        </Stack>
        {/* <Divider /> */}
        {!props?.isNotCollapse && (
          <Collapse defaultActiveKey={["1"]} onChange={onChange}>
            <Panel header={props?.overview || "Summary Statistics"} key="1">
              <Stack
                alignItems={"center"}
                direction={"row"}
                justifyContent={"center"}
                spacing={4}
              >
                {props?.listSummary?.map((summary: any) => (
                  <Paper elevation={3}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <SummaryCard
                        title={summary.title}
                        value={summary.value}
                        bgColor={summary?.bgColor}
                      />
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Panel>
          </Collapse>
        )}
      </Stack>
      <Modal
        title={props?.modelTitle}
        open={props?.isModalOpen}
        onCancel={props?.handleCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">{props?.ModelComponent}</div>
        </div>
      </Modal>
    </>
  );
};

export default Component;
