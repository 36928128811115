import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createBlogService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/blogs`, data, token);
};

export const createBlogCatService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/blogs/category`, data, token);
};

export const getAllBlogsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/blogs${query}`, token);
};

export const getAllBlogsCateoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/blogs/category${query}`, token);
};

export const getOneBlogService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/blogs/one/${id}`, token);
};

export const updateBlogService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/blogs/one/${itemId}`,
    data,
    token
  );
};

export const updateBlogcategoryService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/blogs/category/one/${itemId}`,
    data,
    token
  );
};
