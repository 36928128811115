import React, { useEffect, useState } from 'react';
import { getAllUsersAction } from '../../../store/team/actions';
import { getAllOrderCardsAction, updateOrderCardAction } from '../../../store/ordercard/actions';
import { useDispatch, useSelector } from "react-redux";


interface Order {
  name: string;
  phone: string;
  email: string;
  dates: Date;
  cartTotal: string;
  location: string;
  product: {
    name: string;
    picture: string;
    quantity: number;
    price: string;
    category: string;
    totalPrices: string;
  }[];
  status: '';
}

interface OrderTableProps {
  orders: Order[];
};
const OrderTable: React.FC<OrderTableProps> = ({ orders }) => {

  const { auth, user, ordercard, product } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [setOrders] = useState();
  const dataTable = document.getElementById("data-table");
  const statusFilter = document.getElementById("status-filter");
  const calculateItemTotal = (item: any) => {
    const price = parseFloat(item.price);
    const quantity = parseInt(item.quantity, 10);
    return price * quantity;
  };

  const handleStatusChange = (index: any, newStatus: any) => {
    const updatedOrders = [...orders];
    updatedOrders[index].status = newStatus;
    // setOrders(updatedOrders);
  };
  const handleComplete = (value: any) => {
    updateOrderCardAction(auth.token, value, {
      status: 'complete',
    })(dispatch);
  }
  const handleCancel = (value: any) => {
    updateOrderCardAction(auth.token, value, {
      status: 'Cancel',
    })(dispatch);
  }

  useEffect(() => {
    getAllUsersAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  useEffect(() => {
    getAllOrderCardsAction(auth.token, "status=pending")(dispatch);
  }, [auth.token, dispatch]);


  console.log(ordercard.all.data, 'Himbaza...12');

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-black dark:text-gray-400 bg-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-4 py-2">Name</th>
            <th scope="col" className="px-4 py-2">Phone</th>
            <th scope="col" className="px-4 py-2">Email</th>
            <th scope="col" className="px-4 py-2">Dates</th>
            <th scope="col" className="px-4 py-2">ProductName</th>
            <th scope="col" className="px-4 py-2">Quantity</th>
            <th scope="col" className="px-4 py-2">Amount</th>
            <th scope="col" className="px-4 py-2">Location</th>
            <th scope="col" className="px-4 py-2">Status</th>
            <th scope="col" className="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {ordercard.all.data?.map((order: any, index: any) => (
            <tr key={index._id} style={{ backgroundColor: order?.status === 'complete' ? 'green' : 'white' }}>
              <td scope="col" className="px-6 py-3">{order?.name}</td>
              <td scope="col" className="px-6 py-3">{order?.phone}</td>
              <td scope="col" className="px-6 py-3">{order?.email}</td>
              <td scope="col" className="px-6 py-3">{order?.createdAt}</td>
              <td scope="col" className="px-6 py-3">
                {order.product?.map((p: any) => (
                  <div key={p._id} >
                    <div >
                      <div >{p?.name}</div>
                    </div>
                  </div>

                ))}
              </td>
              <td scope="col" className="px-6 py-3">
              {order.product?.map((p: any) => (
                  <div key={p._id} >
                    <div >
                      <p >{p?.quantity}</p>
                    </div>
                  </div>

                ))}
              </td>
              <td scope="col" className="px-6 py-3">{order?.cartTotal}</td>
              <td scope="col" className="px-6 py-3">{order?.location}</td>
              <td scope="col" className="px-6 py-3">{order?.status}</td>

              <td>
                {order.status === 'pending' && (
                  <div><ul>
                    <button className="items-center justify-between text-black-700 px-1 py-1  font-medium border-" onClick={() => handleComplete(order._id)}>Complete</button>

                    <button className="items-center justify-between  text-red-700 px-1 py-1  font-medium border-" onClick={() => handleCancel(order._id)}>Cancel</button>
                  </ul>

                  </div>
                )}
              </td>
            </tr>
          ))}


        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
