import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../components/HeaderComponents";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddNewProduct from "../../../components/forms/NewProductForm";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "../../../components/grids/product/ProductGrid";
import { getAllProductsAction } from "../../../store/product/actions";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import ProductCatTable from "../../../components/tables/ProductCatTable";
import NewProductCategoryForm from "../../../components/forms/NewProductCategoryForm";

const PageView = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { product, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    auth?.token && getAllProductsAction(auth?.token, `?`)(dispatch);
  }, [auth?.token, dispatch]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Stack spacing={1}>
      <div className="text-black items-center justify-between mt-7 my-auto flex ml-5 mr-20">
        <DetailsHeaderActionBar
          pageName="Products \ Category"
          goBack={goBack}
          title=" "
        />
        <HeaderComponent
          item="Category"
          modelTitle="Add New Category"
          isNotCollapse={true}
          ModelComponent={
            <NewProductCategoryForm
              dataToUpdate={null}
              action={"add"}
              handleCancel={handleCancel}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>
      <ProductCatTable />
    </Stack>
  );
};

export default PageView;
