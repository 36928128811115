import React from "react";
import { useState, useEffect } from "react";
import { DraftEditor } from "../../../components/DraftEditor";
import { EditorState /*convertToRaw*/ } from "draft-js";
import { Cascader, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UploadComponent from "../../../components/Upload/Upload";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import {
  createBlogsAction,
  getAllBlogsCategoryAction,
} from "../../../store/blogPost/actions";

const AddPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, blog } = useSelector((state: any) => state);

  const [propTextState, setPropTextState] = useState(EditorState.createEmpty());
  const [categories, setCategories] = useState<Array<Object>>([]);
  const [selectedCategories, setSelectedCategories] = useState<any>("");
  const [pictures, setPictures] = useState<Array<Object>>([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState(null);

  const handleSubmit = async () => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if (
      !title ||
      !description ||
      !content ||
      !pictures ||
      !selectedCategories
    ) {
      return notification.error({ message: "Please fill all field" });
    }
    if (auth?.token) {
      await createBlogsAction(auth.token, {
        title,
        description,
        category: selectedCategories,
        picture: images,
        content,
      })(dispatch);
      notification?.success({ message: "Created Successfully" });
      navigate(-1);
    }
  };

  useEffect(() => {
    if (blog?.allCategory) {
      let organizedCategories: Array<Object> = [];
      blog?.allCategory?.data?.map((category: any) => {
        return organizedCategories.push({
          value: category?._id,
          label: category?.categoryName,
        });
      });
      setCategories(organizedCategories);
    }
  }, [blog?.allCategory]);

  useEffect(() => {
    if (auth?.token) {
      getAllBlogsCategoryAction(auth.token, "?")(dispatch);
    }
  }, [auth, dispatch]);

  return (
    <div className="text-gray-900">
      <div className="py-4">
        <DetailsHeaderActionBar pageName="Blogs" title="Add Post" />
      </div>
      <div className="flex flex-col justify-center items-center w-[100%] bg-white">
        <div className="flex flex-col justify-between items-center w-[100%]">
          <h1 className="block text-[17px] md:text-2xl font-semibold text-gray-800 py-6 md:text-3x">
            Add a new Post
          </h1>
          <div className="flex flex-col justify-center items-center w-[100%] mt-6">
            <div className="flex flex-row w-[100%] justify-around">
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Title of the post"
                  required
                />
              </div>
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Top image
                </label>
                <UploadComponent setPictures={setPictures} limit={1} />
              </div>
            </div>
            <div className="flex flex-row w-[100%] justify-around">
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2"
                  placeholder="Short description of the post"
                  required
                />
              </div>
              <div className="mb-6 w-[40%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Category
                </label>
                <Cascader
                  id="category"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Category"
                  options={categories}
                  onChange={(e) => setSelectedCategories(e)}
                  value={selectedCategories}
                />
              </div>
            </div>
            <div className="mb-6 w-[90%]">
              <div className="w-[100%] flex flex-row justify-between items-center">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Body/Content
                </label>
              </div>
              <DraftEditor
                placeholder={content}
                editorState={propTextState}
                setEditorState={setPropTextState}
                setContent={setContent}
              />
            </div>
            <Button
              onClick={handleSubmit}
              loading={blog?.isFetching}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 my-5 text-center"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPost;
