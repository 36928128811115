import React from "react";
import * as convert from "../../../assets/data/productExport";
import TeamCard from "./TeamGridCard";

const App = (props: any) => {
  const data = props?.data?.map((d: any) =>
    convert.createExportUserData(
      d.names,
      d.phone,
      d.email,
      d.password,
      d.role,
      d.nid,
      d.picture,
      d
    )
  );

  return data.map((d: any) => <TeamCard data={d} />);
};

export default App;
