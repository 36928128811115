import { notification } from "antd";
import { myBlogActions } from ".";
import {
  createBlogService,
  getAllBlogsService,
  getAllBlogsCateoryService,
  createBlogCatService,
  updateBlogcategoryService,
  getOneBlogService,
  updateBlogService,
} from "./services";

export const createBlogsAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await createBlogService(data, token);
      const resAll = await getAllBlogsService(token, "");
      if (res?.status === 201) {
        dispatch(myBlogActions.setNew(res));
        dispatch(myBlogActions.setAll(resAll));
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createBlogCatAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await createBlogCatService(data, token);
      const resAll = await getAllBlogsCateoryService(token, "?");
      console.log("res?.status", res);
      if (res?.status === 201) {
        dispatch(myBlogActions.setAllCat(resAll));
        dispatch(myBlogActions.setIsFetching(false));
        notification.success({ message: "Category Added Successfully" });
      } else if (res?.response?.data?.status === 500) {
        notification.error({ message: "Category Name already exist" });
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllBlogsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await getAllBlogsService(token, query);
      if (res?.status === 200) {
        dispatch(myBlogActions.setAll(res));
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllBlogsCategoryAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await getAllBlogsCateoryService(token, query);
      if (res?.status === 200) {
        dispatch(myBlogActions.setAllCat(res));
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneBlogAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await getOneBlogService(id, token);
      if (res?.status === 200) {
        dispatch(myBlogActions.setSelectedBlog(res?.data));
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateBlogPostAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await updateBlogService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllBlogsService(token, "");
        dispatch(myBlogActions.setAll(resAll));
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateBlogsCatctsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myBlogActions.setIsFetching(true));
      const res = await updateBlogcategoryService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllBlogsCateoryService(token, "");
        dispatch(myBlogActions.setAllCat(resAll));
        dispatch(myBlogActions.setIsFetching(false));
      }
      dispatch(myBlogActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
