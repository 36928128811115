import { useState } from "react";
import { IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Carousel, Modal, Tag } from "antd";
import { searchValue } from "../../../utils/setColor";
import DeleteModal from "../../Modals/DeleteModal";
import PricePopover from "../../Modals/PricePopover";
import UpdatePriceForm from "../../forms/UpdatePriceForm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { updateProductsAction } from "../../../store/product/actions";
import NewProductForm from "../../forms/NewProductForm";
import { Switch } from "antd";

const Component = (props: any) => {
  const { auth, product } = useSelector((state: any) => state);
  const [openUpdatePopover, setOpenUpdatePopover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priceListId, setPriceListId] = useState("");
  
  const itemPrice = props?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();
  const handleChangeStatus = async (id: string, data: boolean) => {
    let itemStatus;
    if (auth?.token && id) {
      if(data){
        
        itemStatus = 'Active';
      }else {
        itemStatus = 'Inactive';
      }
      await updateProductsAction(auth.token, id, {
        status: itemStatus,
      })(dispatch);
    }
  };

  const handleOpenUpdatePopover = (newOpen: boolean) => {
    setOpenUpdatePopover(newOpen);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnClickView = (value: any) => {
    setIsModalOpen(true);
    setPriceListId(value);
  };

  const [visible, setVisible] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setShowEdit(false);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await updateProductsAction(auth?.token, props?.data._id, {
        isDeleted: true,
      })(dispatch));
  };

  const handleEdit = async () => {
    setShowEdit(true);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon color="green" text={`${itemPrice} RWF`}>
          <div
            className="relative w-80 h-52 p-2 pt-10 bg-white text-black rounded-md"
            onClick={() => handleOnClickView(props?.data?._id)}
          >
            <div className="flex w-fit">
              <div style={{ width: "5rem" }}>
                <Carousel autoplay>
                  {props?.data?.images?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <Badge
                className="site-badge-count-10 text-sm p-2 h-1"
                style={{ backgroundColor: "#faad14" }}
              >
                <div className="flex flex-col pl-2 gap-x-1">
                  <h1 className="font-bold text-lg">{props?.data?.product}</h1>
                  <h1 className="font-thin text-sm">
                    <span className="mr-2">quantity:</span>
                    {`${props?.data?.quantity}KG`}
                  </h1>
                </div>
              </Badge>
            </div>
          </div>
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2">
            <Tag
              color={searchValue(props?.data?.category?.name)}
              className="capitalize"
            >
              {props?.data?.category?.name}
            </Tag>

            {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
              <>
                <PricePopover
                  title="Update price"
                  handleOpen={handleOpenUpdatePopover}
                  open={openUpdatePopover}
                  content={
                    <UpdatePriceForm
                      handleOpenUpdatePopover={handleOpenUpdatePopover}
                      productId={props?.data?._id}
                      dataToUpdate={{
                        value: parseInt(itemPrice?.split(",")?.join("")),
                        extendedWarranty: props?.data?.extendedWarranty,
                      }}
                    />
                  }
                  icon={<AttachMoneyIcon fontSize="small" />}
                />
                <IconButton
                  aria-label="fingerprint"
                  color="primary"
                  size="small"
                  onClick={handleEdit}
                >
                  <BorderColorIcon />
                </IconButton>
                <Switch
                checked={(props?.data?.status === "Active") ?? true}
                onChange={(e) =>
                  handleChangeStatus(props?.data?._id, e)
                }
                className="bg-gray-800 text-xl"
                defaultChecked
                  />
                <IconButton
                  aria-label="fingerprint"
                  color="error"
                  size="small"
                  onClick={handleClickDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </div>
        </Badge.Ribbon>
      </div>

      <Modal
        title={"Edit Product Details"}
        open={showEdit}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className="w-[100vh]">
            {
              <NewProductForm
                dataToUpdate={props?.data}
                action={"update"}
                handleCancel={handleCancel}
              />
            }
          </div>
        </div>
      </Modal>
      {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
        <>
          <DeleteModal
            visible={visible}
            onOk={deleteProduct}
            isLoading={product?.isFetching}
            onCancel={handleDeleteModalCancels}
            itemName="Product"
          />
        </>
      )}
    </>
  );
};

export default Component;
