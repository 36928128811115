import { Stack, Skeleton } from "@mui/material";
import HeaderComponent from "../../../components/HeaderComponents";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import AddNewProduct from "../../../components/forms/NewProductForm";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "../../../components/grids/product/ProductGrid";
import { getAllProductsAction } from "../../../store/product/actions";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const PageView = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { product, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    auth?.token && getAllProductsAction(auth?.token, `?`)(dispatch);
  }, [auth?.token, dispatch]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Stack spacing={1}>
      <div className="text-black items-center justify-between mt-7 my-auto flex ml-5 mr-20">
        <DetailsHeaderActionBar pageName="Products" goBack={goBack} title=" " />
        <HeaderComponent
          item="Product"
          modelTitle="Add New Products"
          isNotCollapse={true}
          ModelComponent={
            <AddNewProduct
              dataToUpdate={null}
              action={"add"}
              handleCancel={handleCancel}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={product?.total}
        count={Math.ceil(product?.total / limit)}
      >
        {product?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 15 }).map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!product.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
            {product?.all?.status === 200 && (
              <ProductGrid data={product?.all?.data} />
            )}
          </div>
      
        )}
      </ScrollableFrame>
    </Stack>
  );
};

export default PageView;
