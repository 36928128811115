import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createApitourism = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/apitourism`, data, token);
};

export const createApitourismCatService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/apitourism/category`,
    data,
    token
  );
};

export const getAllApitourism = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/apitourism${query}`, token);
};
export const getAllApitourismCategoryService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/apitourism/category${query}`, token);
};

export const getOneApitourism = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/apitourism/one/${id}`, token);
};

export const updateApitourism = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/apitourism/one/${itemId}`,
    data,
    token
  );
};

export const updateApitourismCatService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/apitourism/category/one/${itemId}`,
    data,
    token
  );
};
