import * as React from "react";
import ProfileButton from "./ProfileButton";

const Component: React.FC<{
  nav: any;
  handleFullScreenClick: any;
}> = ({ nav, handleFullScreenClick }) => {
  return (
    <div className="fixed top-0 w-full z-10">
      <div
        className={`relative w-full px-4 pt-1 h-16 gap-1 bg-white text-black pl-[80px]`}
      >
        <div className="flex flex-grow">
          <div className="flex  justify-between items-center space-x-0 z-50 flex-1">
            <div className=""></div>
            {nav}
            <ProfileButton handleFullScreenClick={handleFullScreenClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
