import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authActions } from "../store/auth";
import store from "store";

const DashRoutes = () => {
  const dispatch = useDispatch();
  const token = store.get("authToken");
  const { auth } = useSelector((state: any) => state);
  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
  }, [auth, dispatch, token]);
  return !auth?.token || !token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={"/dashboard"} />
  );
};

export default DashRoutes;
