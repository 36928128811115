import { Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import DashboardRoutes from "./DashRoutes";
import LoginProtection from "./LoginProtection";
import Dashboard from "../pages/dashboard/Dashboard";
import SuccessPage from "../components/Results/SuccessfullySent";
import PageNotFound from "../pages/PageNotFound";

//Service
import ServiceRoutes from "./ServicesRoutes";
import Service from "../pages/dashboard/Service";
import Apitourism from "../pages/dashboard/servise/Apitourism";

//Product
import ProductRoutes from "./ProductsRoutes";
import Product from "../pages/dashboard/products/Products";
import ProductCategory from "../pages/dashboard/products/ProductCategory";

//Team
import TeamRoutes from "./TeamRoutes";
import TeamsMember from "../pages/dashboard/team/TeamMember";

//Testimonial
import TestimonialRoutes from "./TestimonialRoutes";
import Testimonial from "../pages/dashboard/testimonial/Testimonial";

//Blog
import BlogsRoutes from "./BlogsRoutes";
import Blogs from "../pages/dashboard/blogs/Blogs";
import AddPost from "../pages/dashboard/blogs/AddPost";
import EditBlogPost from "../pages/dashboard/blogs/EditBlogPost";
import ViewBlogPost from "../pages/dashboard/blogs/ViewBlogPost";
import ApitourismCategory from "../pages/dashboard/servise/ApitourismCategory";
import ServicesRoutes from "./ServicesRoutes";
import OrderCardRoutes from "./OrderCardRoutes";
import OrderCard from "../pages/dashboard/ordercard/OrderCard";

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/success" element={<SuccessPage />} />
      <Route element={<LoginProtection />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Route>
      <Route element={<DashboardRoutes />}>
        {/* ++++++++++++++++ Product +++++++++++++++++++++++++++++ */}
        <Route element={<ProductRoutes />}>
          <Route path="/products" element={<Product />} />
          <Route path="/products/category" element={<ProductCategory />} />
        </Route>

        {/* ++++++++++++++++ Services +++++++++++++++++++++++++++++ */}
        <Route element={<ServiceRoutes />}>
          <Route path="/services" element={<Apitourism />} />
          <Route path="/services/category" element={<ApitourismCategory />} />
        </Route>

        {/* ++++++++++++++++ Testimonail +++++++++++++++++++++++++++++ */}
        <Route element={<TestimonialRoutes />}>
          <Route path="/testimonial" element={<Testimonial />} />
        </Route>

        {/* ++++++++++++++++ Service +++++++++++++++++++++++++++++ */}
        {/* <Route element={<ServicesRoutes />}>
          <Route path="/Apitourism" element={<Service />} />
        </Route> */}

        {/* ++++++++++++++++ Team +++++++++++++++++++++++++++++ */}
        <Route element={<TeamRoutes />}>
          <Route path="/team" element={<TeamsMember />} />
        </Route>

        {/* ++++++++++++++++ News Letter +++++++++++++++++++++++++++++ */}
        <Route element={<OrderCardRoutes />}>
          <Route path="/ordercard" element={<OrderCard />} />
        </Route>

        {/* ++++++++++++++++ Blogs +++++++++++++++++++++++++++++ */}
        <Route element={<BlogsRoutes />}>
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/add" element={<AddPost />} />
          <Route path="/blogs/view/:blogId" element={<ViewBlogPost />} />
          <Route path="/blogs/:blogId" element={<EditBlogPost />} />
        </Route>

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/splash" element={<Dashboard />} />
        <Route path="/*" element={<PageNotFound />} />
      </Route>
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRouter;
