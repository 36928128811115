import store from "store";
import { authActions } from ".";
import {
  loginService,
  profileService,
  userProfileBasicService,
} from "./authServices";

export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await loginService(data);
      if (res?.status === 200) {
        store.set("authToken", res?.data?.data?.token);
        store.set("user", res?.data?.data?.user);
        dispatch(
          authActions.login({ ...res?.data, status: res?.status, isAuth: true })
        );
        dispatch(authActions.setIsFetching(false));
      } else {
        dispatch(authActions.login({ error: res.data, isAuth: false }));
        dispatch(authActions.login({ error: null }));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const profileAction = (token: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await profileService(token);
      if (res?.status === 200) {
        dispatch(authActions.setUser(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const userProfileBasicAction = (email: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await userProfileBasicService(email);
      if (res?.status === 200) {
        dispatch(authActions.setProfile(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
