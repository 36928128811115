import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../Modals/DeleteModal";
import { updateBlogsCatctsAction } from "../../../store/blogPost/actions";
import { Modal } from "antd";
import NewBlogCategoryForm from "../../forms/NewBlogCategoryForm";

const BlogCategory = () => {
  const { auth, blog } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState("");
  const [blogCatId, setBlogCatId] = useState("");
  const dispatch = useDispatch();

  const handleClickDelete = async (id: string) => {
    setVisible(true);
    setBlogCatId(id);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
    setBlogCatId("");
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await updateBlogsCatctsAction(auth?.token, blogCatId, {
        isDeleted: true,
      })(dispatch));
    setVisible(false);
  };

  const handleUpdateModel = (value: any) => {
    setOpenUpdateModel(true);
    setDataToUpdate(value);
  };

  const cancelUpdateModel = (value: any) => {
    setOpenUpdateModel(false);
    setDataToUpdate("");
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center w-[90%]">
        <div className="flex flex-row justify-between items-center w-[100%]">
          <h1 className="block text-[17px] md:text-2xl font-semibold text-gray-800 py-6 md:text-3x">
            Manage categories
          </h1>
        </div>
        <div className="w-[100%] overflow-x-auto">
          <div className="bg-white  rounded my-6 w-[100%]">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-[#f6f8fa] text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-center">Created By</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {blog?.allCategory?.data?.map((category: any) => (
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 text-left whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="font-medium">
                          {category?.categoryName}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <span>{category?.createdBy?.names}</span>
                    </td>
                    <td className="py-3 px-6 text-center">
                      <div className="flex item-center justify-center">
                        <div
                          className="w-4 mr-2 transform hover:text-[#1f2937] hover:scale-110"
                          onClick={() => handleUpdateModel(category)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </div>
                        <button
                          onClick={() => handleClickDelete(category._id)}
                          className="w-4 mr-2 transform text-red-500 hover:scale-110"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={blog?.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Blog Category"
      />

      <Modal
        title={"Update Blog's Category"}
        open={openUpdateModel}
        onCancel={cancelUpdateModel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className="w-[100vh]">
            {
              <NewBlogCategoryForm
                dataToUpdate={dataToUpdate}
                action={"update"}
                handleCancel={cancelUpdateModel}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BlogCategory;
