import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../components/HeaderComponents";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";

import {
  getAllBlogsAction,
  getAllBlogsCategoryAction,
} from "../../../store/blogPost/actions";
import NewBlogCategoryForm from "../../../components/forms/NewBlogCategoryForm";
import BlogPost from "../../../components/grids/blog/BlogPost";
import BlogCategory from "../../../components/grids/blog/BlogCategory";

const Blogs = (_props: any) => {
  const { auth } = useSelector((state: any) => state);
  const [activeTab, setActiveTab] = useState("posts");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const nagivate = useNavigate();

  useEffect(() => {
    getAllBlogsAction(auth.token, "?")(dispatch);
    getAllBlogsCategoryAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="text-black flex items-center justify-between mt-7 ml-5 pb-4 lg:mr-8 2xl:mr-24">
        <DetailsHeaderActionBar pageName="Blogs" title=" " />
        {activeTab === "posts" ? (
          <Button
            variant="contained"
            onClick={() => nagivate("/blogs/add")}
            sx={{ minWidth: 150 }}
            style={{
              backgroundColor: "#fcc31ad5",
              color: "white",
              fontSize: "14px",
            }}
          >
            Add New Blog
          </Button>
        ) : (
          <HeaderComponent
            item="Blog Category"
            modelTitle="Add New Blog Category"
            isNotCollapse={true}
            ModelComponent={
              <NewBlogCategoryForm
                dataToUpdate={null}
                action={"add"}
                handleCancel={handleCancel}
              />
            }
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleCancel={handleCancel}
          />
        )}
      </div>
      <div className="flex flex-col justify-center items-center bg-white">
        <div className="text-base font-medium text-center text-gray-500 border-b border-gray-200">
          <ul className="flex flex-row">
            <li className="w-60">
              <button
                onClick={() => setActiveTab("posts")}
                className={`inline-block w-[100%] p-4 border-b-2 transition-colors duration-300 transform ${
                  activeTab === "posts"
                    ? "border-b-2 text-gray-800  border-gray-800"
                    : "border-transparent rounded-t-lg hover:text-gray-600"
                }`}
              >
                Posts
              </button>
            </li>
            <li className="w-60">
              <button
                onClick={() => setActiveTab("categories")}
                className={`inline-block w-[100%] p-4 border-b-2 transition-colors duration-300 transform ${
                  activeTab === "categories"
                    ? "border-b-2 text-gray-800  border-gray-800"
                    : "border-transparent rounded-t-lg hover:text-gray-600"
                }`}
              >
                Categories
              </button>
            </li>
          </ul>
        </div>
        {activeTab === "posts" && <BlogPost />}
        {activeTab === "categories" && <BlogCategory />}
      </div>
    </>
  );
};

export default Blogs;
