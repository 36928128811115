import * as React from "react";
import { Box, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import profilePic from "../../assets/images/profile.jpeg";
import { authActions } from "../../store/auth";
import { useSelector, useDispatch } from "react-redux";
import Notification from "../../components/notification/Notification";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const propsObj = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const notification = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export default function AccountMenu(props: any) {
  const dispatch = useDispatch();
  const { auth, notifications } = useSelector((state: any) => state);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorElNotification);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElNotification(null);
  };

  const [initialItemsCount, setInitialItemsCount] = React.useState(5);
  const [additionalItemsCount, setAdditionalItemsCount] = React.useState(5);
  const [loadedItemsCount, setLoadedItemsCount] =
    React.useState(initialItemsCount);

  React.useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight
      ) {
        setLoadedItemsCount((prevCount) => prevCount + additionalItemsCount);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [additionalItemsCount]);

  return (
    <React.Fragment>
      <Stack alignItems={"center"} justifyContent="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            width: "30vh",
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              spacing={1}
              alignItems="center"
              justifyContent={"center"}
            >
              <Tooltip title="Notifications">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={handleClickNotification}
                >
                  <Badge
                    badgeContent={3}
                    color={"error"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "2px",
                    }}
                  >
                    <NotificationsIcon sx={{ color: "black" }} />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Stack>

            <Tooltip title="Account settings">
              <div className="flex items-center">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant={navigator.onLine ? "dot" : "standard"}
                  >
                    <img
                      className="w-12 h-12 object-cover rounded-full mr-1"
                      alt="Remy Sharp"
                      src={auth?.user?.picture || profilePic}
                    />
                  </StyledBadge>
                </IconButton>

                <div className="flex flex-col justify-start items-start">
                  <span className="text-sm text-left text-slate-900 font-bold leading-[14px] capitalize">
                    {auth?.user?.names}
                  </span>

                  <span className="text-xs text-left text-blue-800 font-normal capitalize min-w-[5rem] pt-1">
                    {auth?.user?.role?.split("-")?.join(" ").toString()}
                  </span>
                </div>
              </div>
            </Tooltip>
          </Stack>
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={propsObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            dispatch(authActions.logout());
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      {notifications?.all?.data?.length > 0 && (
        <Menu
          anchorEl={anchorElNotification}
          id="account-menu"
          open={openNotification}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={notification}
          defaultValue={3}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <h1 className="pl-4 text-xl font-bold">Notifications</h1>
          <Divider />
          <div className="h-[35rem] overflow-y-auto">
            {notifications?.all?.data?.map((notification: any, index: any) => (
              <Notification data={notification} />
            ))}
          </div>
        </Menu>
      )}
    </React.Fragment>
  );
}
