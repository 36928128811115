import { Stack } from "@mui/material";
import HeaderComponent from "../../../components/HeaderComponents";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import NewApitourismCategoryForm from "../../../components/forms/NewApitourismCategoryForm";
import ApiCatTable from "../../../components/tables/ApiCatTable";

const PageView = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Stack spacing={1}>
      <div className="text-black items-center justify-between mt-7 my-auto flex ml-5 mr-20">
        <DetailsHeaderActionBar
          pageName="Apitourism \ Category"
          goBack={goBack}
          title=" "
        />
        <HeaderComponent
          item="Category"
          modelTitle="Add New Category"
          isNotCollapse={true}
          ModelComponent={
            <NewApitourismCategoryForm
              dataToUpdate={null}
              action={"add"}
              handleCancel={handleCancel}
            />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>
      <ApiCatTable />
    </Stack>
  );
};

export default PageView;
