import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllServiceOrderCard = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/ordercard${query}`, token);
};

export const getOneServiceOrderCard = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/ordercard${itemId}`, token);
};

export const updateService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/ordercard/one/${itemId}`,
    data,
    token
  );
};


