import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import SideNav from "./SideNav";
import store from "store";

import { authActions } from "../../store/auth";

const Layout = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { auth } = useSelector((state: any) => state);
  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleFullScreenClick = () => {
    if (elementRef.current) {
      elementRef.current.requestFullscreen();
    }
  };

  return (
    <div className="landScreenBack">
      <div
        className={` gap-8 w-full h-screen overflow-y-auto ${
          !props?.dashback && "landScreen"
        } `}
      >
        {!props?.dashback && <SideNav selected={props?.selectedNav || 0} />}
        <div className="flex min-h-screen">
          {!props?.dashback && <div className="xw-fit"></div>}
          <div className="w-full min-h-screen pt-10">
            <Header
              nav={props?.nav}
              handleFullScreenClick={handleFullScreenClick}
            />
            <div className={`p-4 h-[calc(100%-64px)] pl-[95px]`}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
