import { notification } from "antd";
import { myOrderCardActions } from ".";
import {
  getAllServiceOrderCard,
  getOneServiceOrderCard,
  updateService
} from "./services";

export const getOneOrderCardAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myOrderCardActions.setIsFetching(true));
      const res = await getOneServiceOrderCard(id, token);
      if (res?.status === 200) {
        dispatch(myOrderCardActions.setSelected(res?.data));
        dispatch(myOrderCardActions.setIsFetching(false));
      }
      dispatch(myOrderCardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllOrderCardsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myOrderCardActions.setIsFetching(true));
      const res = await getAllServiceOrderCard(token, query);
      if (res?.status === 200) {
        dispatch(myOrderCardActions.setAll(res));
        dispatch(myOrderCardActions.setIsFetching(false));
      }
      dispatch(myOrderCardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };

};
export const updateOrderCardAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myOrderCardActions.setIsFetching(true));
      const res = await updateService(itemId, data, token);
      console.log(res,'hugali');
      if (res?.status === 200) {
        const resAll = await getAllServiceOrderCard(token, "");
        dispatch(myOrderCardActions.setAll(resAll));
        dispatch(myOrderCardActions.setIsFetching(false));
      }
      dispatch(myOrderCardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};