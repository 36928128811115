import HeaderComponent from "../../../components/HeaderComponents";
import { Stack, Box, Skeleton } from "@mui/material";
import TeamGrid from "../../../components/grids/team/TeamGrid";
import React, { useEffect, useState } from "react";
import AddNewTeamMember from "../../../components/forms/NewTeamForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getAllUsersAction } from "../../../store/team/actions";
import axios from "axios";
import Item from "antd/es/list/Item";
import profile from "assets/images/profile.jpeg";
import { getAllOrderCardsAction,updateOrderCardAction } from "../../../store/ordercard/actions";
import DeleteIcon from "@mui/icons-material/Delete";



function PageView(props: any) {
  const [isGridView, setIsGridView] = useState(true);
  const { auth, user, ordercard,product } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const calculateItemTotal = (item: any) => {
    const price = parseFloat(item.price);
    const quantity = parseInt(item.quantity, 10);
    return price * quantity;
  };
  const handlecanceDelete = async (value: any) => {
    setUserToDelete("");
    setVisible(false);
  };
  
  
  

  const deleteTestimonial = async () => {
    auth?.token &&
      userToDelete &&
      (await updateOrderCardAction(auth?.token, userToDelete, {
        isDeleted: true,
      })(dispatch));
    setVisible(false);
  };
  

  useEffect(() => {
    getAllUsersAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  useEffect(() => {
    getAllOrderCardsAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);
  
  // new code

  const completeOrder = async (order: any) => {
    // Mark the order as complete and remove it from the table
    await updateOrderCardAction(auth?.token, order._id, { status: 'complete', isDisAbles: true })(dispatch);
    setVisible(false);
  };

  const cancelOrder = async (order: any) => {
    // Mark the order as canceled and remove it from the table
    await updateOrderCardAction(auth?.token, order._id, { status: 'canceled', isDisAbles: true })(dispatch);
    setVisible(false);
  };

  console.log(ordercard.all, product.all,calculateItemTotal, 'higa');


  return (
    <Stack>
      
      <div className="items-center justify-between mt-7 ml-5 lg:mr-8 2xl:mr-24">
        <DetailsHeaderActionBar />

      </div>
      <div className="cardsx flex flex-wrap gap-3 px-14" >
       {ordercard.all.data?.map((order:any)=>{
        console.log(order,'sifahome');
        return(
          

          <div className="  max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden mb-2">
            {order.product?.map((p:any)=>(
                <div key={p._id} className=" flex flex-wrap getAllServiceOrderCard">
                  <img src={p?.picture} alt={p?.name} className="w-25 h-20 object-cover " />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-1">{p?.name}</div>
            <p className="text-gray-700 text-base">Category: {p?.category}</p>
            <p className="text-gray-700 text-base">Price: {p?.price} RWF</p>
            <p className="text-gray-700 text-base">Quantity: {p?.quantity}</p>
            
            
          </div>
                </div>

              ))}
          
          <div className="px-6 py-4">
            <p className="text-gray-700 text-base">Name: {order?.name}</p>
            <p className="text-gray-700 text-base">Phone: {order?.phone}</p>
            <p className="text-gray-700 text-base">Email: {order?.email}</p>
            <p className="text-gray-700 text-base">Location: {order?.location}</p>
            <p className="text-gray-700 text-base">Total Amount: {order?.cartTotal} RW</p>
            <p className="text-gray-700 text-base">Date: {order?.createdAt}</p>
          </div>
          <div className="items-center justify-between mt-2 ml-5 lg:mr-8 2xl:mr-10 mb-10">
          {(order.status==='pending' || order.status==='complete') && <button onClick={() => completeOrder(order)} className="bg-green-500 text-white mr-10 mb-10 h-10 w-20 rounded-lg font-medium border-radius-25">
              Complete
            </button>}
            {(order.status==='pending' || order.status==='canceled') &&<button onClick={() => cancelOrder(order)} className="bg-red-700 text-white mr-10 mb-10 h-10 w-20 rounded-lg font-medium border-radius-25">
              Cancel
            </button>}
          </div>
          
          <DeleteModal
        visible={visible}
        onOk={deleteTestimonial}
        onCancel={handlecanceDelete}
        itemName={"OrderCard"}
        isLoading={ordercard?.isFetching}
      />
        </div>
        )}) }
     </div>
      

    </Stack>
  );
            }


export default PageView;
