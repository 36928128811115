import {
  Stack,
  Box,
  Button,
  MenuItem,
  MenuList,
  Paper,
  Grow,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TopNav = (props: any) => {
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState([""]);
  const [selectedNav, setSelectedNav] = useState(0);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
        >
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className="capitalize"
          >
            {props?.pages.map((page: any, index: number) => {
              if (page?.skip) return null;
              return (
                <Button
                  key={index}
                  sx={{
                    color: selectedNav === index ? "black" : "#89899c",
                    minWidth: 100,
                  }}
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    setSelectedNav(index);
                    navigate(page?.path);
                    page?.sub && handleToggle();
                    page?.sub && setMenuList(page?.sub);
                  }}
                  ref={page?.sub && anchorRef}
                  endIcon={page?.sub && <KeyboardArrowDownIcon />}
                >
                  {page?.caption}
                </Button>
              );
            })}
          </Box>
        </Stack>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper sx={{ zIndex: 90, background: "white" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {menuList.map((m: any) => {
                    if (m?.skip === true) return null;
                    return (
                      <MenuItem
                        onClick={(event: Event | React.SyntheticEvent) => {
                          handleClose(event);
                          navigate(m?.path);
                        }}
                      >
                        {m.caption}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default TopNav;
