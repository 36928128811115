import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { Form, Input, Select } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { EditorState /*convertToRaw*/ } from "draft-js";
import {  notification } from "antd";
import UploadComponent from "../../components/forms/Upload";


import {
  createProductAction,
  getAllProductsCategoryAction,
  updateProductsAction,
} from "../../store/product/actions";
import { DraftEditor } from "../DraftEditor";

// const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewProductForm: React.FC<{
  dataToUpdate: any;
  action: String;
  handleCancel: any;
}> = ({ dataToUpdate, action, handleCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const [imageValue, setImageValue] = useState("");
  const [myImages, setMyImages] = useState<any[]>(
    dataToUpdate ? dataToUpdate?.images : []
  );

  const productUnit = ["box", "kg", "litle"];
  const { product, auth } = useSelector((state: any) => state);
  const isUpdate = action === "update";
  const [content, setContent] = useState(null);
  const [propTextState, setPropTextState] = useState(EditorState.createEmpty());

  const onFinish = async (values: any) => {
    if (
      !content 
      
    ) {
      return notification.error({ message: "Description is required" });
    }
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    const payload = {
      ...values,
      productDescription: content,
      prices: [
        {
          value: values?.prices,
        },
      ],
      images: images,
    };

    if (dataToUpdate) {
      updateProductsAction(auth.token, dataToUpdate?._id, {
        ...values,
        images: [...images, ...myImages],
      })(dispatch);
    } else {
      createProductAction(auth?.token, { ...payload })(dispatch);
    }

    form.resetFields();
    handleCancel();
  };

  useEffect(() => {
    if (!dataToUpdate) {
      auth?.token && getAllProductsCategoryAction(auth?.token, "?")(dispatch);
    }
  }, [auth?.token, dispatch]);

  const initialData = {
    ...dataToUpdate,
    category: dataToUpdate?.category?.name,
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialData || {}}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
    >
      <div className="grid grid-cols-2 mr-6">
        <Form.Item
          name="product"
          label="Product Name"
          rules={[
            {
              required: true,
              message: "Name is required!",
              whitespace: true,
            },
          ]}
          style={{ width: 300 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="productType"
          label="Product Type"
          rules={[
            {
              required: true,
              message: "Product Type is required!",
              whitespace: true,
            },
          ]}
          style={{ width: 300 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="productUnit"
          label="Product Unit"
          rules={[
            {
              required: true,
              message: "Product Unit is required!",
            },
          ]}
          style={{ width: 300 }}
        >
          <Select
            showSearch
            allowClear
            loading={product.isFetching}
            disabled={product.isFetching}
            style={{ width: 200 }}
            placeholder="Product Unit"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toString().includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
            options={productUnit?.map((unit: any) => ({
              label: `${unit}`,
              value: unit,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[
            {
              required: true,
              message: "Quantity is required!",
            },
          ]}
          style={{ width: 300 }}
        >
          <Input type="number" />
        </Form.Item>
        {!dataToUpdate && (
          <>
            <Form.Item
              name="prices"
              label="Price"
              rules={[
                {
                  required: true,
                  message: "Price is required!",
                },
              ]}
              style={{ width: 300 }}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name="category"
              label="Product Category"
              initialValue={dataToUpdate?.category?.name}
              rules={[
                {
                  required: true,
                  message: "Category is required!",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                loading={product.isFetching}
                disabled={product.isFetching}
                style={{ width: 200 }}
                placeholder="Select Product Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.productCategory?.data?.map(
                  (category: any) => ({
                    label: `${category?.name}`,
                    value: category?._id,
                  })
                )}
              />
            </Form.Item>
          </>
        )}
        {/* <Form.Item
          name="productDescription"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description is required!",
            },
          ]}
        >
         
        </Form.Item> */}
        <div className="mb-6 w-[90%]">
              <div className="w-[100%] flex flex-row justify-between items-center">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Description
                </label>
              </div>
              <DraftEditor
                placeholder={content}
                editorState={propTextState}
                setEditorState={setPropTextState}
                setContent={setContent}
              />
            </div>
        <Form.Item label="Add Pro">
          {/* <Space.Compact style={{ width: "100%" }}>
            <Input
              value={imageValue}
              onChange={(e: any) => setImageValue(e.target.value)}
            />
            <Button
              onClick={() => {
                onSearch();
              }}
              style={{
                backgroundColor: "#fcc31ad5",
                color: "white",
                fontSize: "10px",
              }}
            >
              Save
            </Button>
          </Space.Compact> */}
          <UploadComponent setPictures={setPictures} isCard={"picture-card"} />
        </Form.Item>
        
      </div>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="flex-start"
        justifyContent={"start"}
        sx={{ width: "100%" }}
      >
        <Stack sx={{ width: "60%" }}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              style={{
                backgroundColor: "#fcc31ad5",
                color: "white",
                fontSize: "14px",
              }}
              loading={product?.isFetching}
            >
              {isUpdate ? "Update" : "Submit"}
            </LoadingButton>
          </Form.Item>
        </Stack>
      </Stack>
    </Form>
  );
};

export default NewProductForm;
