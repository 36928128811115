import { LoadingButton } from "@mui/lab";
import { Form, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateProductsAction } from "../../store/product/actions";

const UpdatePriceForm: React.FC<{
  productId: string;
  dataToUpdate?: any;
  handleOpenUpdatePopover?: any;
}> = ({ productId, dataToUpdate, handleOpenUpdatePopover }) => {
  const [form] = Form.useForm();
  const { auth, product } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    auth?.token &&
      (await updateProductsAction(auth?.token, productId, {
        prices: {
          ...values,
        },
      })(dispatch));
    handleOpenUpdatePopover(false);
    notification.success({ message: "Price Updated Successfully" });
  };

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={dataToUpdate}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
      >
        <Form.Item
          name="value"
          label="Product Price"
          tooltip="Please enter Product price?"
          rules={[
            {
              required: true,
              message: "Price is required!",
              whitespace: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            style={{
              backgroundColor: "#fcc31ad5",
              color: "white",
              fontSize: "14px",
            }}
            loading={product?.isFetching}
          >
            {"update"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdatePriceForm;
