export const createExportUserData = (
  names: String,
  phone: String,
  email: String,
  password: String,
  role: String,
  nid: Boolean,
  picture?: any,
  data?: any
) => {
  return { names, phone, email, password, role, nid, data, picture };
};
