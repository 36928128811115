import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import store from "store";
const DashRoutes = () => {
  const token = store.get("authToken");

  let authent = { token: true };
  const pagesArray = [
    {
      caption: "Services",
      path: "/services",
    },
    {
      caption: "Category",
      path: `/services/category`,
    },
  ];

  return authent.token || token ? (
    <DashLayout
      allowSearch={true}
      nav={
        <TopNav
          nav={listManages.find(
            (item) => item.title.toLowerCase() === "Service".toLowerCase()
          )}
          pages={pagesArray}
        />
      }
      selectedNav={1}
    >
      <Outlet />
    </DashLayout>
  ) : (
    <Navigate to="/dashboard" />
  );
};

export default DashRoutes;
