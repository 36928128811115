import React from "react";

function Notification({ data }: { data: any }) {
  return (
    <div
      className="bg-white
      w-full px-5 py-2 border-white  min-w-[450px] hover:bg-slate-100"
      key={data.id}
    >
      <div className="flex flex-row justify-between align-center gap-x-[20px] border-b-2">
        <img
          src={
            data.profile ??
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
          }
          alt="profile"
          className="rounded-[1000px] w-[40px] h-[40px] object-cover cursor-pointer"
        />

        <div className="flex flex-col w-full gap-[5px] cursor-pointer">
          <p className="font-bold">{data.action}</p>
          <p className="text-[#111827] text-[12px]">{data.message}</p>
          <p className="text-[12px]">{data.createdAt?.slice(0, 10)}</p>
        </div>
      </div>
    </div>
  );
}

export default Notification;
